<template>
  <section>
    <v-container>
      <v-row
        class="pt-15"
        justify="center"
        align="center"
      >
        <v-col
          cols="11"
          md="8"
          lg="8"
          xl="5"
        >
          <kn-form-title
            :title="routerName"
            :routerName="routerName"
          />
          <v-row justify="start">
            <v-col 
              cols="12"
              md="4"
              lg="4"
              xl="4"
            >
              <v-btn
                outlined
                tile
                block
                color="primary"
                @click="valueDeterminate = 100"
              >
                {{ title }}
              </v-btn>
            </v-col>
            <v-col cols="12">
              <v-progress-linear
                v-model="valueDeterminate"
                color="primary"
                rounded
                height="8"
              ></v-progress-linear>
            </v-col>
          </v-row>
          <kn-form-note-of-mandatory />
          
          <div v-if="valueDeterminate === 100">
            <v-form
              ref="form"
              v-model="valid"
              lazy-validation
            >
              <v-row>
                <v-col
                  class="pb-0"
                  cols="12"
                  md="4"
                  lg="4"
                  xl="4"
                >
                  <v-subheader>Fecha:</v-subheader>
                </v-col>
                <v-col
                  class="pb-0" 
                  cols="12"
                  md="4"
                  lg="4"
                  xl="4"
                >
                  <v-text-field
                    v-model="expenseDate"
                    dense
                    outlined
                    type="date"
                    hint="DD /MM /YYYY"
                    persistent-hint
                    :disabled="readonly"
                  >
                    <template v-slot:append-outer>
                      <v-icon color="primary">mdi-calendar-month-outline</v-icon>
                    </template>
                  </v-text-field>
                </v-col>
              </v-row>
              <v-row>
                <v-col
                  class="py-0"
                  cols="12"
                  md="4"
                  lg="4"
                  xl="4"
                >
                  <v-subheader>Destinatario:</v-subheader>
                </v-col>
                <v-col class="py-0">
                  <v-radio-group
                    v-model="receiver"
                    row
                    :disabled="readonly"
                  >
                    <v-radio
                      label="Empleado"
                      value="empleado"
                    ></v-radio>
                    <v-radio
                      label="Proveedor"
                      value="proveedor"
                    ></v-radio>
                  </v-radio-group>
                </v-col>
              </v-row>
              <v-row v-if="receiver === 'proveedor'">
                <v-col
                  class="py-0"
                  cols="12"
                  md="4"
                  lg="4"
                  xl="4"
                >
                  <v-subheader>Proveedor:</v-subheader>
                </v-col>
                <v-col class="py-0">
                  <v-autocomplete
                    v-model="expense.id_proveedor"
                    dense
                    outlined
                    :rules="[rules.required]"
                    :disabled="readonly"
                    :items="suppliers"
                    item-text="datos_fiscales[0].nombre_o_razon_social"
                    item-value="id"
                    @change="fillRFC"
                  >
                    <template #item="{ item }">
                      {{ supplierName(item) }}
                    </template>
                    <template #selection="{ item }">
                      {{ supplierName(item) }}
                    </template>
                  </v-autocomplete>
                </v-col>
              </v-row>
              <v-row v-if="receiver === 'empleado'">
                <v-col
                  class="py-0"
                  cols="12"
                  md="4"
                  lg="4"
                  xl="4"
                >
                  <v-subheader>Empleado:</v-subheader>
                </v-col>
                <v-col class="py-0">
                  <v-autocomplete
                    v-model="expense.id_empleado"
                    dense
                    outlined
                    :rules="[rules.required]"
                    :disabled="readonly"
                    :items="employees"
                    item-text="datos_fiscales.nombre_o_razon_social"
                    item-value="id"
                    @change="fillRFC"
                  />
                </v-col>
              </v-row>
              <kn-text-field
                v-model="expense.rfc"
                label="RFC:"
                :rules="[]"
                :disabled="readonly"
                :isMandatory="false"
              />
              <kn-select
                v-model="expense.id_categoria"
                label="Concepto:"
                :rules="[rules.required]"
                :disabled="readonly"
                :items="expenseCategories"
                item-text="dato"
                item-value="id"
              />
              <kn-select
                v-model="expense.id_sub_categoria"
                label="Sub categoria:"
                :rules="[rules.required]"
                :disabled="readonly"
                :items="expenseSubcategories"
                item-text="dato"
                item-value="id"
              />
              <kn-text-field
                v-model.number="expense.sub_total"
                label="Sub total:"
                :rules="[rules.required]"
                :disabled="readonly"
                @input="calculateTotal"
              />
              <kn-check-box
                class="mt-0"
                v-model="hasTaxes"
                label="Calcular impuestos:"
                :rules="[]"
                :disabled="readonly"
                :isMandatory="false"
                @input="calculateTotal"
              />
              <kn-text-field
                v-model.number="expense.total_impuestos"
                label="Total impuestos:"
                :rules="mandatoryTaxRules"
                :disabled="readonly"
                :isMandatory="mandatoryTaxRules.length > 0"
                :suffix="taxSuffix"
                @input="calculateTotal"
              />
              <kn-text-field
                v-model.number="expense.total_descuento"
                label="Total descuento:"
                :rules="[]"
                :disabled="readonly"
                @input="calculateTotal"
              />
              <kn-text-field
                v-model.number="expense.total_egreso"
                label="Total:"
                :rules="[rules.required]"
                :disabled="readonly"
              />
              <kn-select
                v-model="expense.id_moneda"
                label="Moneda:"
                :rules="[rules.required]"
                :disabled="readonly"
                :items="coins"
                item-text="nombre_moneda"
                item-value="id"
              />
              <kn-select
                v-model="expense.id_estatus"
                label="Estatus:"
                :rules="[rules.required]"
                :disabled="readonly"
                :items="expenseStatus"
                item-text="dato"
                item-value="id"
              />
              <kn-select
                v-model="expense.id_forma_de_pago"
                label="Forma de pago:"
                :rules="[rules.required]"
                :disabled="readonly"
                :items="paymentMethods"
                item-text="dato"
                item-value="id"
              />
              <kn-doc-item
                v-if="showUpload"
                class="mb-5"
                idInput="inputCom"
                label="Agregar comprobante:"
                colBtn="4"
                :disabled="readonly"
                :isMandatory="false"
                @saveFile="saveReceipt"
                @clearFile="clearReceipt"
              />
              <kn-download-doc-item
                v-if="showDownload"
                class="mb-5"
                :doc="receipt"
                label="Comprobante:"
                colBtn="4"
                :disabled="readonly"
                @deleteDoc="clearReceipt"
              />
              <kn-select
                v-model="expense.id_cuenta_origen"
                label="Cuenta origen:"
                :rules="[rules.required]"
                :disabled="readonly"
                :items="bankAccounts"
                item-text="nombre_cuenta"
                item-value="id"
              />
              <kn-text-area
                v-model.number="expense.comentarios"
                label="Comentarios:"
                :rules="[]"
                :disabled="readonly"
                :isMandatory="false"
              />
              <!-- <kn-form-subtitle title="" />
              <v-row>
                <v-col
                  class="pb-0"
                  cols="12"
                  md="6"
                  lg="6"
                  xl="6"
                >
                  <v-checkbox label="Generar factura" dense />
                </v-col>
              </v-row> -->
            </v-form>
            <kn-form-action-buttons
              :disableAccept="readonly"
              @accept="save"
              @cancel="returnToTable"
            />
          </div>
          <!-- Fin pestania -->
        </v-col>
      </v-row>
    </v-container>
    <kn-back-to-top-button />
    <kn-local-alert
      v-model="showAlert"
      :alertText="alertText" 
      :alertType="alertType"
      :alertColor="alertColor"
      :loadingAlert="loading"
      @action1="actionAlertBtn1"
      @action2="continueAdding"
    />
  </section>
</template>

<script>
// import { api } from '@/api/axios-base'
import { mapState } from 'vuex'
import KnDocItem from '@/components/forms/KnDocItem.vue'
import KnFormActionButtons from '@/components/KnFormActionButtons.vue'
// import KnFormSubtitle from '@/components/KnFormSubtitle.vue'
import KnLocalAlert from '@/components/KnLocalAlert.vue'
import KnDownloadDocItem from '@/components/forms/KnDownloadDocItem.vue'
import KnFormTitle from '@/components/KnFormTitle.vue'
import KnBackToTopButton from '@/components/forms/KnBackToTopButton.vue'
import KnTextField from '@/components/inputs/KnTextField.vue'
import KnSelect from '@/components/inputs/KnSelect.vue'
import KnCheckBox from '@/components/inputs/KnCheckBox.vue'
import { apiMixin } from '@/mixins/apiMixin'
import { generalFetchingMixin } from '@/mixins/generalFetchingMixin'
import { dateUtilsMixin } from '@/mixins/dateUtilsMixin'
import { fileUtilsMixin } from '@/mixins/fileUtilsMixin'
import { validationFormMixin } from '@/mixins/validationFormMixin'
import KnFormNoteOfMandatory from '@/components/KnFormNoteOfMandatory.vue'
import KnTextArea from '@/components/inputs/KnTextArea.vue'

export default {
  components: {
    // KnFormSubtitle, 
    KnFormActionButtons,
    KnDocItem,
    KnLocalAlert,
    KnDownloadDocItem,
    KnFormTitle,
    KnBackToTopButton,
    KnTextField,
    KnSelect,
    KnCheckBox,
    KnFormNoteOfMandatory,
    KnTextArea,
  },
  mixins: [
    apiMixin,
    generalFetchingMixin,
    dateUtilsMixin,
    fileUtilsMixin,
    validationFormMixin
  ],
  props: {
    entity: {
      type: Object,
      default: null
    },
    readonly: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      valueDeterminate: 100,
      routerName: 'Egresos',
      valid: true,
      expense: {
        id_proveedor: null,
        id_empleado: null,
        id_categoria: null,
        id_sub_categoria: null,
        sub_total: 0.00,
        total_impuestos: 0.00,
        costo_envio: 0.00,
        total_descuento: 0.00,
        total_egreso: 0.00,
        descuento_aplicado: false,
        id_forma_de_pago: null,
        id_moneda: 1,
        id_cuenta_origen: null,
        id_estatus: null,
        id_centro_de_costos: 1,
        id_autor: null,
        id_institucion_educativa: null,
        comentarios: ''
      },
      hasTaxes: false,
      expenseId: null,
      receiver: null,
      suppliers: [],
      employees: [],
      expenseCategories: [],
      expenseSubcategories: [],
      paymentMethods: [],
      coins: [],
      bankAccounts: [],
      expenseStatus: [],
      costCenter: [],
      institutions: [],
      authors: [],
      errors: [],
      loading: false,
      showAlert: false,
      alertType: 'info',
      alertText: 'Registro exitoso',
      alertColor: null,
      expenseDate: null,
      receipt: null,
      receiptIdToDelete: null,
    }
  },
  computed: {
    ...mapState(['institutionId', 'userData']),
    title() {
      return this.entity === null ? 'Registro de egresos' : 'Detalle de egresos'
    },
    isNewMode() {
      return (this.entity === null)
    },
    successAlertText() {
      return this.isNewMode ? 'Egreso registrado con exito!' : 'Egreso actualizado con exito!'
    },
    successAlertType() {
      return this.isNewMode ? 'success' : 'info'
    },
    showDownload() {
      return !this.isNewMode && this.receipt !== null && this.receipt.id
    },
    showUpload() {
      return this.receipt === null || (this.receipt !== null && !this.receipt.id)
    },
    taxSuffix() {
      return this.hasTaxes ? '16%' : '0%'
    },
    mandatoryTaxRules() {
      return this.hasTaxes ? [this.rules.required] : []
    }
  },
  async created() {
    this.loading = true
    this.alertText = 'Cargando... Por favor, espera'
    this.showAlert = true

    this.expense.id_autor = this.userData.id
    this.expense.id_institucion_educativa = this.institutionId
    this.suppliers = await this.fetchResultsByEI('personas', 'proveedor', this.institutionId) // await this.fetchSuppliersByEI(this.institutionId)
    this.employees = await this.fetchResultsByEI('personas', 'empleado', this.institutionId) // await this.fetchEmployeesByEI(this.institutionId)
    this.expenseCategories = await this.fetchResultsByEI('administracion', 'categoria-egreso', this.institutionId) // await this.fetchExpenseCategoriesByEI(this.institutionId)
    this.expenseSubcategories = await this.fetchResultsByEI('administracion', 'subcategoria-egreso', this.institutionId) // await this.fetchExpenseSubCategoriesByEI(this.institutionId)
    this.paymentMethods = await this.fetchResultsByEI('administracion', 'forma-de-pago', this.institutionId) // await this.fetchPaymentMethodsByEI(this.institutionId)
    this.coins = await this.fetchResultsByEI('administracion', 'moneda', this.institutionId) // await this.fetchCoinsByIE(this.institutionId)
    this.expenseStatus = await this.fetchResultsByEI('administracion', 'estatus-egreso', this.institutionId) // await this.fetchExpenseStatusByEI(this.institutionId)
    this.bankAccounts = await this.fetchResultsByEI('administracion', 'cuanta', this.institutionId) // await this.fetchBankAccountsByEI(this.institutionId)
    this.institutions = await this.fetchResultsAll('personas', 'institucion-educativa-all') // await this.fetchEducationalInstitution()
    
    const today = new Date()
    this.expenseDate = this.getDate(today)

    if (!this.isNewMode) {
      // console.log('Se deben llenar los campos');
      this.expenseId = this.entity.id
      this.fillExpense()
    }
    this.showAlert = false
  },
  methods: {
    async postExpense() {
      try {
        const response = await this.postObj('/administracion/crear-egreso', this.expense)
        // const responseData = await response.data
        // console.log('Respuesta crear egreso', response);
        if (response.error) {
          this.errors.push('No se pudo realizar el registro del egreso')
        } else {
          this.expenseId = response.id_objeto
        }
      } catch (error) {
        console.error('Error al intentar crear egreso');
      }
    },
    async updateExpense() {
      try {
        this.expense.id_subcategoria = this.expense.id_sub_categoria
        const response = await this.postObj('/administracion/update-egreso', this.expense)
        // const responseData = await response.data
        // console.log('Respuesta crear egreso', response.data);
        if (response.error) {
          this.errors.push('No se pudo actualizar del egreso')
        }
      } catch (error) {
        console.error('Error al intentar actualizar egreso');
      }
    },
    async save() {
      if (this.$refs.form.validate()) {
        this.loading = true
        this.alertText = 'Cargando...'
        this.showAlert = true
        if (this.isNewMode) {
          await this.postExpense()
          if (this.errors.length === 0 && this.receipt !== null) {
            await this.createReceiptDocument()
          }
        } else {
          await this.updateExpense()
          if (this.errors.length === 0) {
            await this.correctAccountBalance()
          }
          if (this.errors.length === 0) {
            if (this.receiptIdToDelete !== null) {
              await this.removeReceiptFromExpense(this.receiptIdToDelete, this.expenseId)
              await this.deleteReceiptPermanently(this.receiptIdToDelete)
            }
            if (this.receipt !== null && this.receipt.file) {
              await this.createReceiptDocument()
            }
          }
        }
        this.loading = false
        if (this.errors.length) {
          this.alertType = 'error'
          this.alertText = this.errors.join(', ')
        } else {
          this.alertType = this.successAlertType
          this.alertColor = 'success'
          this.alertText = this.successAlertText
        }
      }
    },
    calculateTotal() {
      let {
        sub_total,
        total_impuestos,
        total_descuento,
        total_egreso
      } = this.expense

      total_impuestos = sub_total * (this.hasTaxes ? 0.16 : 0.0)
      total_egreso = (sub_total + total_impuestos) - total_descuento
      this.expense.total_impuestos = total_impuestos
      this.expense.total_egreso = total_egreso
    },
    actionAlertBtn1() {
      if (this.alertType === 'success' || this.alertType  === 'info') {
        this.returnToTable()
      } else {
        this.closeAlert()
      }
    },
    continueAdding() {
      this.clean()
      this.closeAlert()
    },
    returnToTable() {
      this.$router.replace({name: this.routerName})
    },
    closeAlert() {
      this.showAlert = false
    },
    clean() {
      this.expense = {
        id_proveedor: null,
        id_empleado: null,
        id_categoria: null,
        id_sub_categoria: null,
        sub_total: 0.00,
        total_impuestos: 0.00,
        costo_envio: 0.00,
        total_descuento: 0.00,
        total_egreso: 0.00,
        descuento_aplicado: false,
        id_forma_de_pago: 1,
        id_moneda: 1,
        id_cuenta_origen: null,
        id_estatus: 1,
        id_centro_de_costos: 1,
        id_autor: this.userData.id,
        id_institucion_educativa: this.institutionId
      }
    },
    fillExpense() {
      this.expenseDate = this.getDateWithTimeZeroFullStr(this.entity.fecha_elaboracion)
      this.receiver = this.entity.proveedor 
                      ? 'proveedor' 
                      : this.entity.empleado 
                        ? 'empleado' 
                        : null
      // console.log('Destinatario:', this.receiver);
      this.expense = {
        id: this.entity.id,
        id_proveedor: this.entity.proveedor ? this.entity.proveedor.id : null,
        id_empleado: this.entity.empleado ? this.entity.empleado.id : null,
        id_categoria: this.entity.categoria.id,
        id_sub_categoria: this.entity.sub_categoria.id,
        sub_total: this.entity.sub_total,
        total_impuestos: this.entity.total_impuestos,
        costo_envio: this.entity.costo_envio,
        total_descuento: this.entity.total_descuento,
        total_egreso: this.entity.total_egreso,
        descuento_aplicado: this.entity.descuento_aplicado,
        id_forma_de_pago: this.entity.forma_de_pago.id,
        id_moneda: this.entity.moneda.id,
        id_cuenta_origen: this.entity.cuenta_origen.id,
        id_estatus: this.entity.estatus.id,
        id_centro_de_costos: this.entity.centro_de_costos.id,
        id_autor: this.userData.id, // Debe ser el usuario logueado
        id_institucion_educativa: this.institutionId, // Debe ser la institucion del usuario logueado
        comentarios: this.entity.comentarios ? this.entity.comentarios : ''
      }
      this.receipt = this.entity.comprobantes.length > 0 ? this.entity.comprobantes[0] : null
      this.fillRFC()
    },
    saveReceipt(file) {
      this.receipt = file
    },
    clearReceipt(file) {
      this.receipt = null
      if (!this.isNewMode && file.id !== null) {
        this.receiptIdToDelete = file.id
      }
    },
    async assignReceiptToExpense(receiptId, expenseId) {
      try {
        const response = await this.postObj('/administracion/agregar-comprobante-egreso', {
          id_egreso: expenseId,
          id_comprobante: receiptId
        })
        // const responseData = await response.data
        return response
      } catch (error) {
        console.error('Error al intentar asignar comprobante a egreso', error);
      }
    },
    async removeReceiptFromExpense(receiptId, expenseId) {
      try {
        const response = await this.postObj('/administracion/remove-comprobante-egreso', {
          id_egreso: expenseId,
          id_comprobante: receiptId
        })
        // const responseData = await response.data
        return response
      } catch (error) {
        console.error('Error al intentar remover el comprobante de ingreso', error);
      }
    },
    async createReceiptDocument() {
      /** Para agregar un comprobante al egreso se 
       * realizan los siguientes pasos:
       * - Subir archivo
       * - Crear documento comprobante
       * - Agregar comprobante egreso
       * 
       * Ya que agregar comprobante necesita id de
       * comprobante e id de egreso se debe llamar
       * este método despues de crear el egreso
       */
      const responseUpload = await this.uploadFile(this.receipt)
      if (responseUpload && responseUpload.mensaje.includes('Archivo guardado con éxito')) {
        const { nombre_archivo, url_archivo } = responseUpload.archivos[0]
        const docObj = {
          nombre_documento: nombre_archivo,
          url_documento: url_archivo,
          id_autor: this.userData.id,
          id_institucion_educativa: this.institutionId
        }
        const responsePostDoc = await this.postReceiptDoc(docObj)

        if (responsePostDoc && responsePostDoc.mensaje.includes('operación exitosa')) {
          const receiptId = responsePostDoc.id_objeto
          const responseAssign = await this.assignReceiptToExpense(receiptId, this.expenseId)

          if (responseAssign && responseAssign.mensaje.includes('operación exitosa')) {
            this.alertText = 'Comprobante asignado exitosamente'
          } else {
            this.errors.push('No se pudo asignar el comprobante de ingreso')
          }
        }
      }
    },
    /**
     * Incrementa balance de cuenta y crea movimiento de cuenta
     * @param {object} objToIncrease Objeto con los datos para incrementar balance
     */
    async increaseAccountBalance(objToIncrease) {
      try {
        const response = await this.postObj('/administracion/aumentar-balance-cuenta', objToIncrease)
        if (response.error) {
          this.errors.push('No se pudo incrementar balance de cuenta: ' + response.error)
        } else {
          this.alertText = 'Balance incrementado con éxito'
        }
      } catch (error) {
        this.alertText = 'Ocurrió un error al intentar incrementar balance de cuenta'
      }
    },
    /**
     * Decrementa balance de cuenta y crea movimiento de cuenta
     * @param {object} objToDecrease Objeto con los datos para decrementar balance
     */
    async decreaseAccountBalance(objToDecrease) {
      try {
        const response = await this.postObj('/administracion/disminuir-balance-cuenta', objToDecrease)
        if (response.error) {
          this.errors.push('No se pudo decrementar balance de cuenta: ' + response.error)
        } else {
          this.alertText = 'Balance decrementado con éxito'
        }
      } catch (error) {
        this.alertText = 'Ocurrió un error al intentar decrementar balance de cuenta'
      }
    },
    /**
     * Corrección de balance de cuenta. Toma el monto anterior
     * y lo suma al balance, luego toma el monto actualizado
     * y lo resta al balance.
     */
    async correctAccountBalance() {
      // console.log('Dentro de correctAccountBalance');
      const amountToCorrect = parseFloat(this.entity.total_egreso)
      const correctedAmount = this.expense.total_egreso
      const objForMovement = {
        id_cuenta: this.expense.id_cuenta_origen,
        monto: amountToCorrect,
        id_autor: this.userData.id,
        comentarios: `Entrada por corrección de egreso (ID ${this.expenseId})`
      }
      await this.increaseAccountBalance(objForMovement)

      objForMovement.monto = correctedAmount
      objForMovement.comentarios = `Salida por corrección de egreso (ID ${this.expenseId})`
      await this.decreaseAccountBalance(objForMovement)
    },
    supplierName(supplier) {
      let supplierName = 'Sin nombre'
      if (supplier.nombre_proveedor) {
        supplierName = supplier.nombre_proveedor
      } else if (supplier.datos_fiscales && supplier.datos_fiscales.length > 0) {
        supplierName = supplier.datos_fiscales[0].nombre_o_razon_social
      } else if (supplier.usuario && supplier.usuario.username) {
        supplierName = supplier.usuario.username
      }
      return supplierName
    },
    fillRFC() {
      let person = null
      switch(this.receiver) {
        case 'empleado':
          person = this.employees.find(employee => employee.id === this.expense.id_empleado)
          this.expense.rfc = person.datos_fiscales.tax_id
          break
        case 'proveedor':
          person = this.suppliers.find(supplier => supplier.id === this.expense.id_proveedor)
          this.expense.rfc = person.datos_fiscales &&
              person.datos_fiscales.length > 0 
            ? person.datos_fiscales[0].tax_id 
            : 'No aplica'
          break
        default: this.expense.rfc = ''
      }
    }
  }
}
</script>

<style>

</style>